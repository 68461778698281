import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import React, { FC, useCallback, useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { clusterApiUrl } from '@solana/web3.js';
import { Notification } from './Notification';
import { WithChildren } from './utils';
import { SOLANA_NETWORK } from './constants';

export const WalletProviderWrapper: FC = ({children}: WithChildren<{}>) => {
    //const network = WalletAdapterNetwork.Devnet;
    const network = SOLANA_NETWORK;
    const devnetEndpoint = useMemo(() => clusterApiUrl(network), [network])
    const endpoint = 
        network === WalletAdapterNetwork.Devnet
        ? devnetEndpoint
        //: "https://aged-late-tree.solana-mainnet.quiknode.pro/d64693e6de6a1b1c4b81f076a30ea353aaed1e24/";
        //: "https://solana-mainnet.pixtapes.com";
        //: "https://rough-red-smoke.solana-mainnet.quiknode.pro/2604aaa25335c1cd2e6784c2eecc9218661be4da/";
        //: "https://little-empty-cherry.solana-mainnet.quiknode.pro/f819e11275e35ef4ccd1cca2006cc3082c7b01af/";
        : "https://purple-silent-river.solana-mainnet.quiknode.pro/1de5668e576b09e8738b1ed79d2cefec66d07343/";
        //: devnetEndpoint;

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter(),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    const onError = useCallback(
        (error: WalletError) =>
            toast.custom(
                <Notification
                    message={error.message ? `${error.name}: ${error.message}` : error.name}
                    variant="error"
                />
            ),
        []
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} onError={onError} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
                <Toaster position="bottom-left" reverseOrder={false} />
            </WalletProvider>
        </ConnectionProvider>
    );
};
