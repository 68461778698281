import { WalletProviderWrapper } from './WalletProviderWrapper';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from './constants';
import Mint from './Mint';

export function App() {
	return (
		<WalletProviderWrapper>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} useRecaptchaNet={true}>
				<Mint />
			</GoogleReCaptchaProvider>
		</WalletProviderWrapper>
	);
}