import { PublicKey } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

// TODO: There is a proper way to handle dev/prod config differences
const DEV = false;
const LOCAL = false;
const MAINNET = true;
export const API_ROOT_ENDPOINT =
    DEV
    ? LOCAL
    ? "http://localhost:4000"
    : "https://dev.pixtapes.com/api/v2"
    : "https://pixtapes.com/api/v1";

export const SOLANA_NETWORK =
    MAINNET
    ? WalletAdapterNetwork.Mainnet
    : WalletAdapterNetwork.Devnet;

export const VOTE_ENDPOINT = API_ROOT_ENDPOINT + "/vote";
export const VOTE_BALLOT_ENDPOINT = API_ROOT_ENDPOINT + "/vote/ballot";
export const SALT_ENDPOINT = API_ROOT_ENDPOINT + "/salt";
export const USER_ENDPOINT = API_ROOT_ENDPOINT + "/user";
export const HOME_ENDPOINT = API_ROOT_ENDPOINT + "/home";

//export const PIXGUARD_ENDPOINT = "https://pixtapes.com/PixGuard/api.php";
export const PIXGUARD_ENDPOINT = "https://pixguard.pixtapes.com/api";

// Imported from @solana/spl-token, which we don't use for now but it is likely
// we will eventually so when this happens let's not forget to remove this.
export const TOKEN_PROGRAM_ID = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");

export const SOLANART_PROGRAM = new PublicKey("CJsLwbP1iu5DuUikHEJnLfANgKy6stB2uFgvBBHoyxwz");
export const ALPHA_ART_PROGRAM = new PublicKey("HZaWndaNWHFDd9Dhk5pqUUtsmoBCqzb1MLu3NAh1VX6B");
export const PIXTAPE_CREATOR_ADDRESS = "9WG7S7PofNDrBeNp41vFwmy1BfDdgtTDNWay5KbKxp4t";
export const PIXGUARD_PUBKEY = new PublicKey("PxGm5rTDCDbTbY38vKgErvLXz9uFE1aq878DTKSpVa5");

export const RECAPTCHA_SITE_KEY = "6Lfl_hcdAAAAAIElpO0jUTeO4-l7q7ApnZNn6oQA";

export const PIXCASE_MASTER =
    MAINNET
    ? new PublicKey("HNxqUhTAWYWD7uBsktjj84nJW1TYLZLFQiMhodAXw6Ed")
    : new PublicKey("EeLE2YGVcW3CAF4vqWWYxWrNHcHGniFzgMmZigfnNCiU");

export const PIXCASE_WHITELIST =
    MAINNET
    ? new PublicKey("EwEW2KbhtxEjViKuAgAjJ8LUPJMHyfX9CqJjDaZ9bQet")
    : new PublicKey("sRdQMuSJnuJdtAtQTDVhjzAdoMgK91PYmDqvHMCsFe6");
